import { Box, Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { Field, Form } from 'formik';
import React from 'react';
import useDisableContinue from '../../hooks/useDisableContinue';
import MaterialDateInput from '../MaterialDatePicker';

interface Props {
    isLoading: boolean;
}
const useStyles = makeStyles((theme) => ({
    retrievePolicyButton: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        '&:not(.Mui-disabled)': {
            color: theme.palette.primary.main,
        },
    },
}));

const CustomerPortalLoginForm = ({ isLoading }: Props) => {
    const classes = useStyles();
    const { isDisabled } = useDisableContinue();

    return (
        <Form noValidate>
            <Field name='DateOfBirth' id='verify-date-of-birth' label='Date of birth' component={MaterialDateInput} />
            <Grid container direction='row' justify='center' alignItems='center'>
                <Grid item>
                    <Box pt={2}>
                        <Button
                            data-testid='login-sign-in'
                            disabled={isDisabled || isLoading}
                            type='submit'
                            variant='contained'
                            color='primary'
                            className={classes.retrievePolicyButton}
                            disableElevation
                        >
                            <>Retrieve policy&nbsp;&nbsp;{isLoading && <CircularProgress size={24} />}</>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Form>
    );
};

export default CustomerPortalLoginForm;
