import {
  createStyles,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  makeStyles,
  Theme,
  WithStyles,
} from '@material-ui/core';
import { FieldProps } from 'formik';
import React from 'react';
import MaskedInput from 'react-text-mask';
import clsx from 'clsx';

const get = (o: any, path: string) => path.split('.').reduce((o: any = {}, key: string) => o[key], o);

export type Mask = string | RegExp;
export type DateMask = 'yyyy' | 'dd / mm / yyyy';

interface MaskAndPlaceHolder {
  mask: Mask[];
  placeholder: string;
}
const getMask = (mask?: DateMask): MaskAndPlaceHolder => {
  const maskPattern = mask || 'dd / mm / yyyy';
  if (maskPattern === 'dd / mm / yyyy') {
    return {
      mask: [/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/],
      placeholder: 'DD / MM / YYYY',
    };
  } else if (maskPattern === 'yyyy') {
    return {
      mask: [/\d/, /\d/, /\d/, /\d/],
      placeholder: 'yyyy',
    };
  }

  throw new Error('Masking pattern not identified');
};

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  mask?: DateMask;
}

const TextMaskCustom = (props: TextMaskCustomProps) => {
  const { inputRef, mask, ...other } = props;
  const { mask: inputMask, placeholder } = getMask(mask);
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={inputMask}
      guide={false}
      showMask
      placeholder={placeholder}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => dateInputStyles(theme));

const dateInputStyles = (theme: Theme) =>
  createStyles({
    textField: (styleProps: any) => ({
      margin: styleProps.showLabel ? theme.spacing(2.5, 0) : theme.spacing(0, 0, 2.5),
      '& label.MuiFormLabel-root': {
        color: theme.palette.primary.main,
        fontFamily: 'Spartan MB SemiBold',
        fontSize: '1rem',
        transform: 'scale(1)',
        lineHeight: '1.25rem',
        marginTop: '0rem',
        marginRight: '48px',
        position: 'relative',
      },
      '& .MuiInput-formControl': {
        marginTop: '0px',
      },
      '& .MuiInput-input': {
        fontFamily: 'Spartan MB ',
        fontSize: '1rem',
        paddingBottom: 10,
        paddingTop: 12,
      },
      '& .MuiInput-underline:before': {
        borderBottom: '2px solid #e5e5e5',
      },
      '& .MuiInput-underline': {
        '&:after, &:hover:before': {
          borderBottomColor: theme.palette.secondary.dark,
        },
      },
      '&::placeholder': {
        fontSize: '1rem',
      },
    }),
    labelRoot: {
      color: theme.palette.primary.main,
    },
  });

interface Props {
  label: string;
  mask?: Mask[];
  placeholder?: string;
}

const MaterialDateInput = <P extends object>({
  field,
  form: { touched, errors },
  mask,
  placeholder,
  ...props
}: FieldProps<P> & Props & WithStyles<typeof dateInputStyles>) => {
  const touchedField = get(touched, field.name);
  const errorsField = get(errors, field.name);
  const showLabel = !!props.label;
  const inputProps = (props as any).inputProps || {};

  const rest: any = { ...props };
  const testId = inputProps['data-testid'];
  rest['data-testid'] = `${rest['data-testid']}-inner`;

  const classes = useStyles();

  return (
    <FormControl
      fullWidth
      className={clsx(classes.textField, {
        visibleLabel: showLabel,
      })}
      data-testid={(props as any)['data-testid']}
    >
      {props.label && (
        <InputLabel shrink htmlFor={`${field.name}`}>
          {props.label}
        </InputLabel>
      )}
      <Input
        error={touched && touchedField && errors && Boolean(errorsField)}
        {...rest}
        {...field}
        inputComponent={TextMaskCustom as any}
        inputProps={{
          mask,
          placeholder,
          'data-testid': inputProps['data-testid'],
        }}
        id={`${field.name}`}
      />
      {touchedField && errorsField && (
        <FormHelperText error={true} id={`${testId}-helper-text`}>
          {errorsField}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default MaterialDateInput;